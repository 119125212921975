import React, { FC } from 'react';
import { BrandType } from '../../../models/brand-type';
import { BrandsPanelType } from '../../../models/brands-panels-type';
import BrandCard from '../../molecules/brand-card/brand-card';

const BrandsPanel: FC<BrandsPanelType> = ({
  featuredBrands,
  heading,
}: BrandsPanelType) => {
  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing">
      <h5 className="page-heading-five text-center mb-8">{heading}</h5>

      <div className="grid gap-5 grid-cols-2  md:grid-cols-4 lg:grid-cols-6">
        {featuredBrands.map((brand: BrandType) => (
          <BrandCard key={brand.name} {...brand} />
        ))}
      </div>
    </div>
  );
};

export default BrandsPanel;
